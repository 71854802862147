<template>
  <div id="contact-us">
    <div class="container">
      <!-- Content here -->
      <h1 class="px-4"><strong>Contact Us</strong></h1>
      <div class="row px-4 mt-5 justify-content-between">
        <div class="col-lg-6">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div label-for="input-1">
              <v-text-field type="text" id="name" :disabled="logged_in" v-model="contact.name" aria-describedby="nameHelp" placeholder="Name" :rules="[v => !!v || 'Required!']" outlined></v-text-field>
            </div>
            <div label-for="input-2">
              <v-text-field type="email" id="email" :disabled="logged_in" v-model="contact.email" aria-describedby="emailHelp" placeholder="Email" :rules="emailRules" outlined></v-text-field>
            </div>
            <div class="col">
              <div label-for="input-3" class="row">
                <div class="input-group-prepend p-0 mr-2"><span class="input-group-text" style="height:58px;">+91</span></div>
                <v-text-field type="number" :disabled="logged_in" v-model="contact.mobile" placeholder="Mobile Number" :rules="mobileRules" outlined></v-text-field>
            </div>
          </div>
          <div label-for="input-4">
            <v-textarea type="text" id="exampleInputEmail1" v-model="contact.comment" rows="4" cols="50" aria-describedby="messageHelp" :rules="[v => !!v || 'Required!']" placeholder="Comment" outlined></v-textarea>
          </div>  
          <div class="modal-footer p-0" style="border-top:none;">
            <button type="button" class="btn btn-primary px-7 mx-0" v-on:click="submitContact">Send</button>
          </div>
          <div v-if="sentFlag">
            <p style="font-size:12px;"><strong>Your enquiry has been successfully sent to the admin.</strong></p>
          </div>
        </v-form>
        </div>
        <div class="col-lg-5">
          <h2 class="text-left"><strong>Contact details</strong></h2>
          <div class="mt-3">
            <h6><v-icon class="mdi menu-icon mr-2" size="14px">{{ $icons.emailOutline }}</v-icon>recruit@hireclap.com</h6>
            <h6 class="mt-3"><v-icon class="mdi menu-icon mr-2" size="14px">{{$icons.deskphone}}</v-icon>+91 9037 156 256</h6>
            <h6 class="mt-10"><v-icon class="mdi menu-icon mr-2" size="14px">{{$icons.mapMarker}}</v-icon>21st A Cross Rd, Sector 3, HSR Layout, Bengaluru, Karnataka 560102, India</h6>
          </div>
        </div>
        <!-- <div class="vl"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Actions from '../libraries/apiActions.js';
var breadcrumbJson = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": window.location.origin
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Contact Us",
        "item": window.location.href
      }]
    }
export default({
  name:'contact_us',
  metaInfo: {
    link: [
      { rel: 'canonical', href: process.env.VUE_APP_URL+'contact_us' }
    ],
    script: [
      {type: 'application/ld+json', json: breadcrumbJson},
    ]
  },
  data(){
    return {
      action: new Actions(),
      valid:false,
      logged_in: false,
      contact:{
        name: null,
        email: null,
        mobile: null,
        comment:null,
        src: null,
        uid: null
      },
      sentFlag:false,

      emailRules:[
        v => !!v || 'Required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      mobileRules:[
        v => !!v || 'Required',
        v => !/\D/g.test(v) || 'Enter valid mobile number',
        v => (v && v.length == 10) || 'Enter 10 digit mobile number'
      ],
    }
  },
  async beforeMount() {
    if(this.$cookies.get("hire-seeker"))
      await this.getUser(this.$cookies.get("hire-seeker").token);
  },
  methods: {
    async getUser(token) {
      await this.action.getUser(token).then(async (data) => {
        if (data) {
          this.logged_in = true;
          this.contact.name = data.user.name;
          this.contact.email = data.user.email;
          this.contact.mobile = data.user.mobile;
          this.contact.uid = data.user.id;
        }
      }).catch((err) => {
        console.log(err);
        if (err) {
          // this.$router.replace("/").catch(() => {});
        }
      });
    },
    submitContact(){
      let user = this.$cookies.get("hire-seeker");
      if(user) console.log(user);

      this.valid = this.$refs.form.validate();
      if(this.valid){
        if(this.$route.query.src) this.contact.src = this.$route.query.src;
        this.action.sendContact(this.contact).then(res => {
          if(res.status == 1){
            this.sentFlag = true;
            this.cancel();
          }
        })
      }
    },
    cancel(){
      if(!this.logged_in) {
        this.contact.name = null;
        this.contact.email = null;
        this.contact.mobile = null;
      }
      this.valid = false;
      this.contact.comment = null;
      this.$refs.form.resetValidation()
    }
  },
})
</script>
<style>
  .vl {
    border-left: 6px solid #000;
    height: 500px;
  }
</style>
